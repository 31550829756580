/* eslint-disable no-param-reassign */
<template>
  <div>
    <b-card
      title="รายการแจ้งถอนเงิน"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทร / ยูส"
                label-for="mc-first-name"
              >
                <b-form-input
                  id="mc-first-name"
                  v-model="tableQuery.phoneno"
                  placeholder="เบอร์โทร / ยูส"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="ชื่อ-สกุล"
                label-for="mc-last-name"
              >
                <b-form-input
                  id="mc-last-name"
                  v-model="tableQuery.fullname"
                  placeholder="ชื่อ-สกุล"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="ธนาคาร"
                label-for="mc-city"
              >
                <b-row>
                  <b-col md="6">
                    <v-select
                      v-model="tableQuery.bank_code"
                      label="title"
                      :options="bankListOptions"
                    >
                      <template #option="{ text, value }">
                        <img
                          :src="getBankImage(value)"
                          style="width: 24px;"
                        >
                        <span> {{ text }}</span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col md="6">
                    <b-form-input
                      id="mc-city2"
                      v-model="tableQuery.bank_acc_no"
                      placeholder="เลขที่บัญชี"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="วันที่รายการ"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="searchMember"
              >
                ค้นหา
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-row>
      <b-col
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>สถิติรายการ</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated 1 mins ago
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in stats"
                :key="item.icon"
                xl="3"
                sm="6"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card
          title="รายการยังไม่เรียบร้อย"
        >
          <b-table
            ref="unsuccessTable"
            striped
            hover
            responsive
            class="position-relative"
            busy.sync="false"
            :per-page="1000"
            :current-page="1"
            :items="unsuccessItems"
            :fields="unsuccessTableFields"
          >
            <template #cell(act_actions)="data">
              <div v-if="['awaiting', 'error'].indexOf(data.item.status) >= 0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-info"
                  size="sm"
                  @click="manualWithdrawRecord(data.item, { status: 'manual' })"
                >
                  ถอนมือ
                </b-button><br><br>
              </div>
              <div v-if="['manual', 'error'].indexOf(data.item.status) >= 0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-success"
                  size="sm"
                  @click="markComplete(data.item)"
                >
                  เรียบร้อย
                </b-button><br>
              </div>
              <div v-if="['awaiting'].indexOf(data.item.status) >= 0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="rejectWithdraw(data.item)"
                >
                  ปฎิเสธ
                </b-button><br>
              </div>
              <div v-if="['awaiting'].indexOf(data.item.status) >= 0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="seizeWithdraw(data.item)"
                >
                  ยึด
                </b-button><br><br>
              </div>
              <div v-if="['request', 'manual'].indexOf(data.item.status) >= 0">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-warning"
                  size="sm"
                  @click="cancelOperation(data.item)"
                >
                  ยกเลิก
                </b-button>
              </div>
            </template>
            <template #cell(act_bot)="data">
              <div v-if="['awaiting'].indexOf(data.item.status) >= 0 && data.item.queue === 'M'">
                <div
                  v-for="bot in withdrawableBanks"
                  :key="bot.id"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-info"
                    size="sm"
                    class="mb-1"
                    @click="approveWithdraw(data.item, bot.bank_alias)"
                  >
                    {{ bot.bank_alias }}
                  </b-button>
                  <br>
                </div>
              </div>
              <div v-if="['awaiting'].indexOf(data.item.status) >= 0 && data.item.queue !== 'M'">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-warning"
                  size="sm"
                  @click="approveWithdraw(data.item, data.item.queue)"
                >
                  ถอนอีกครั้ง
                </b-button>
              </div>
            </template>
            <template #cell(status)="data">
              <div>
                <b-badge :variant="{'awaiting':'light-warning', 'processing': 'light-danger', 'error': 'light-danger', 'request': 'light-primary', 'manual': 'light-info'}[data.item.status]">
                  {{ data.item.status_text }}
                </b-badge><br>
                <b-badge
                  v-if="data.item.queue !== 'M'"
                  variant="info"
                >
                  {{ data.item.queue }}
                </b-badge>
              </div>
            </template>
            <template #cell(user)="data">
              <div v-if="data.item.user">
                <b-badge v-if="data.item.user.level != 'MEMBER'" :variant="'light-danger'">
                  {{ data.item.user.level }}
                </b-badge>
                {{ data.item.user.phoneno }}<br>
                {{ data.item.user.username }}<br>
                {{ `${data.item.user.fname} ${data.item.user.lname}` }}
              </div>
            </template>
            <template #cell(bank_acc_no)="data">
              <img
                :src="getBankImage(data.item.user.bank_code)"
                style="width: 24px;"
              > {{ `${data.item.user.bank_name}` }}<br>{{ `${data.item.user.bank_acc_no}` }}
            </template>
            <template #cell(detail)="data">
              <b-badge :variant="{'Y':'light-danger', 'N':'light-primary'}[data.item.on_promotion || 'N']">
                {{ {'Y':'รับโปร', 'N':'ไม่รับโปร'}[data.item.on_promotion || 'N'] }}
              </b-badge><br>
              ยอดเทิร์น {{ `${numberWithCommas(data.item.user.total_turn_amount)}` }} / {{ `${numberWithCommas(data.item.user.withdrawable_turn_amount)}` }}
              <hr>
              {{ data.item.detail }}
              <div
                v-if="data.item.reason"
                class="text-danger"
              ><hr>
                หมายเหตุ {{ data.item.reason }}
              </div>
            </template>
            <template #cell(txn_detail)="data">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px; margin-right: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewDeposit(data.item)"
              >
                ฝาก
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px; margin-right: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewWithdraw(data.item)"
              >
                ถอน
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewBetList(data.item)"
              >
                เล่น
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewWalletList(data.item)"
              >
                กระเป๋า
              </b-button> -->
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card
          title="รายการถอนเรียบร้อย"
        >
          <b-table
            ref="successWithdrawTable"
            striped
            hover
            responsive
            class="position-relative"
            :busy="false"
            :per-page="tableQuery.page_size"
            :current-page="tableQuery.page"
            :items="fetchCompletedList"
            :total-rows="totalRows"
            :fields="completedTableFields"
          >
            <template #cell(user)="data">
              <div v-if="data.item.user">
                <b-badge v-if="data.item.user.level != 'MEMBER'" :variant="'light-danger'">
                  {{ data.item.user.level }}
                </b-badge>
                {{ data.item.user.phoneno }}<br>
                {{ data.item.user.username }}<br>
                {{ `${data.item.user.fname} ${data.item.user.lname}` }}
              </div>
            </template>
            <template #cell(bank_acc_no)="data">
              <img
                :src="getBankImage(data.item.user.bank_code)"
                style="width: 24px;"
              > {{ `${data.item.user.bank_name}` }}<br>{{ `${data.item.user.bank_acc_no}` }}
            </template>
            <template #cell(detail)="data">
              <b-badge :variant="{'Y':'light-danger', 'N':'light-primary'}[data.item.on_promotion || 'N']">
                {{ {'Y':'รับโปร', 'N':'ไม่รับโปร'}[data.item.on_promotion || 'N'] }}
              </b-badge><br>
              ยอดเทิร์น {{ `${numberWithCommas(data.item.user.total_turn_amount)}` }} / {{ `${numberWithCommas(data.item.user.withdrawable_turn_amount)}` }}
              <hr>
              {{ `${data.item.detail}` }}
              <div
                v-if="data.item.reason"
                style="color:red;"
              ><hr>หมายเหตุ ({{ data.item.reason }})</div>
            </template>
            <template #cell(amount)="data">
              <div style="text-align: right;">
                {{ `${numberWithCommas(data.item.amount)}` }}
              </div>
            </template>
            <template #cell(queue)="data">
              <div style="text-align: center;">
                <b-badge :variant="{'error':'light-warning', 'rejected': 'light-danger', 'error': 'light-danger', 'request': 'light-primary', 'manual': 'light-info', 'approved': 'light-success', 'cleared': 'light-success'}[data.item.status]">
                  {{ data.item.status_text }}
                </b-badge><br>
                {{ `${data.item.status === 'rejected' ? data.item.queue.replace('M', ''):data.item.queue.replace('M', 'ถอนมือ')}` }}
                <br>
                <span v-if="data.item.queue !='M'">
                  {{ (withdrawableBanks.filter(w => w.bank_alias == data.item.queue)[0] || {}).acc_name }}
                </span>
                <span
                  v-if="data.item.staff"
                  style="color: white; font-size: 8px;"
                >{{ data.item.staff.name }}</span>
              </div>
            </template>
            <template #cell(slip_url)="data">
              <div
                v-if="data.item.slip_url"
                style="text-align: center;"
              >
                <!-- <b-tooltip
                  :ref="`slip${data.item.id}`"
                  :target="`view-slip-${data.item.id}`"
                >
                  <img :src="data.item.slip_url">
                </b-tooltip> -->
                <a
                  :id="`view-slip-${data.item.id}`"
                  @click="viewSlip(data.item)"
                >ดูสลิป</a>
              </div>
            </template>
            <template #cell(txn_detail)="data">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px; margin-right: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewDeposit(data.item)"
              >
                ฝาก
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px; margin-right: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewWithdraw(data.item)"
              >
                ถอน
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewBetList(data.item)"
              >
                เล่น
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                style="margin-top: 10px;"
                variant="outline-primary"
                size="sm"
                @click="viewWalletList(data.item)"
              >
                กระเป๋า
              </b-button> -->
            </template>
            <template #cell(reason)="data">
              <div
                v-if="data.item.reason"
                style="color:red;"
              ><hr>({{ data.item.reason }})</div>
            </template>
          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="tableQuery.page_size"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="tableQuery.page"
                :total-rows="totalRows"
                :per-page="tableQuery.page_size"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalSlip.show"
      title=""
      header-bg-variant="dark"
      header-text-variant="dark"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      hide-footer
      hide-header
      centered
    >
      <img
        v-if="modalSlip.slip_url"
        :src="modalSlip.slip_url"
        style="width: 400px;"
      >
    </b-modal>
    <b-modal
      v-model="modalDeposit.show"
      :title="modalDeposit.title"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      hide-footer
      centered
      size="lg"
    >
      <b-table
        ref="userDepositsTable"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="modalDeposit.page_size"
        :current-page="modalDeposit.page || 1"
        :items="modalDeposit.data || []"
        :fields="depositsTableFields"
      >
        <template #cell(note)="data">
          <div>
            <b-badge :variant="{'DEPOSIT':'light-success', 'PROMOTION': 'light-warning', 'WITHDRAW': 'light-danger'}[data.item.txtype]">
              {{ data.item.txtype.replace('DEPOSIT', 'ฝาก').replace('PROMOTION', 'รับโปร').replace('WITHDRAW', 'ถอน') }}
            </b-badge><br>
            {{ data.item.note }}
          </div>
        </template>
        <template #cell(amount)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.amount || 0)}` }}
          </div>
        </template>
        <template #cell(sum)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.sum || 0)}` }}
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="ดูย้อนหลังสูงสุด (วัน)"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageDepositSelect"
            v-model="modalDeposit.limit_days"
            style="margin-left: 10px;"
            size="sm"
            inline
            :options="daysOption"
            @change="fetchUserDepositList"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="modalDeposit.page"
            :total-rows="(modalDeposit.data || []).length"
            :per-page="modalDeposit.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="changePage($event, 'deposit')"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-modal>
    <b-modal
      v-model="modalWithdraw.show"
      :title="modalWithdraw.title"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      hide-footer
      centered
      size="lg"
    >
      <b-table
        ref="userWithdrawsTable"
        striped
        hover
        responsive
        class="position-relative"
        :busy.sync="modalWithdraw.busy"
        :per-page="modalWithdraw.page_size"
        :current-page="modalWithdraw.page"
        :items="modalWithdraw.data || []"
        :fields="depositsTableFields"
      >
        <template #cell(note)="data">
          <div>
            <b-badge :variant="{'DEPOSIT':'light-success', 'PROMOTION': 'light-warning', 'WITHDRAW': 'light-danger' }[data.item.txtype]">
              {{ data.item.txtype.replace('DEPOSIT', 'ฝาก').replace('PROMOTION', 'รับโปร').replace('WITHDRAW', 'ถอน') }}
            </b-badge><br>
            {{ data.item.note }}
          </div>
        </template>
        <template #cell(amount)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.amount || 0)}` }}
          </div>
        </template>
        <template #cell(sum)="data">
          <div style="text-align: right;">
            {{ `${numberWithCommas(data.item.sum || 0)}` }}
          </div>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="ดูย้อนหลังสูงสุด (วัน)"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageWithdrawSelect"
            v-model="modalWithdraw.limit_days"
            style="margin-left: 10px;"
            size="sm"
            inline
            :options="daysOption"
            @change="fetchUserWithdrawList"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="modalWithdraw.page"
            :total-rows="(modalWithdraw.data || []).length"
            :per-page="modalWithdraw.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @change="changePage($event, 'withdraw')"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-modal>
    <b-modal
      v-model="modalBetTransactions.show"
      :title="modalBetTransactions.title"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      size="xl"
      hide-footer
      centered
    >
      <bet-detail-component
        :show="modalBetTransactions.show"
        :user-input="modalBetTransactions.username"
      />
    </b-modal>
    <b-modal
      id="modal-reject-withdraw"
      v-model="modalRejectWithdraw.show"
      cancel-variant="outline-secondary"
      ok-title="ปฏิเสธ"
      cancel-title="ยกเลิก"
      centered
      title="ปฎิเสธการถอน"
      @ok="confirmRejectWithdraw"
    >
      <b-form>
        <b-form-group>
          <label for="reject-reason">เหตุผล:</label>
          <b-form-input
            id="reject-reason"
            v-model="modalRejectWithdraw.reason"
            type="text"
            placeholder="เหตุผล"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-seize-withdraw"
      v-model="modalSeizeWithdraw.show"
      cancel-variant="outline-secondary"
      ok-title="ยึดเครดิค"
      cancel-title="ยกเลิก"
      centered
      title="ยืนยันการยึดเครดิต"
      @cancel="cancelSeizedWithdraw"
      @ok="confirmSeizedWithdraw"
    >
      <b-form>
        <b-form-group>
          <label for="reject-reason">เหตุผล:</label>
          <b-form-input
            id="seize-reason"
            v-model="modalSeizeWithdraw.reason"
            type="text"
            placeholder="เหตุผล"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-markcomplete-withdraw"
      v-model="modalMarkCompleteWithdraw.show"
      cancel-variant="outline-secondary"
      ok-title="เรียบร้อย"
      cancel-title="ยกเลิก"
      centered
      title="ยืนยันการเปลี่ยนสถานะรายการถอน"
      @cancel="cancelMarkWithdraw"
      @ok="confirmMarkWithdrawComplete"
    >
      <b-form>
        <b-form-group>
          <label for="reject-reason">เหตุผล:</label>
          <b-form-input
            id="seize-reason"
            v-model="modalMarkCompleteWithdraw.reason"
            type="text"
            placeholder="เหตุผล"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm, BModal, BTooltip, VBTooltip, BCardHeader, BCardTitle, BCardText, BMedia, BAvatar, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import BetDetailComponent from '../members/BetDetailComponent.vue'

import firebase from 'firebase/app'
import 'firebase/database'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BTooltip,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // VBModal,
    BModal,
    vSelect,
    flatPickr,
    BetDetailComponent,
    BCardHeader,
    BCardTitle,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      withdrawableBanks: JSON.parse(localStorage.getItem('bankaccount-list') || '[]'),
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      modalSlip: { show: false },
      modalRejectWithdraw: { show: false },
      modalSeizeWithdraw: { show: false },
      modalMarkCompleteWithdraw: { show: false },
      modalDeposit: {
        show: false,
        busy: false,
        page_size: 20,
        totalRows: 0,
        limit_days: 1,
        title: 'รายการฝาก',
      },
      modalWithdraw: {
        show: false,
        busy: false,
        page_size: 20,
        totalRows: 0,
        limit_days: 1,
        title: 'รายการถอน',
      },
      modalBetTransactions: { show: false, title: 'รายการแทง' },
      modalWalletTxn: { show: false, title: 'รายการกระเป๋าเงิน' },
      isBusy: false,
      isBusyIncompleted: false,
      refRecheck: null,
      pageOptions: [20, 100, 200, 500, 1000],
      daysOption: [1, 3, 7, 14, 30, 60, 90],
      totalRows: 1,
      totalIncompletedRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 20,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      completedTableFields: [
        { key: 'request_datetime', label: 'แจ้งถอน', formatter: val => this.formatDate(val) },
        {
          key: 'approved_datetime',
          label: 'ถอนสำเร็จ',
          formatter: (val, _, data) => (data.status === 'rejected' ? this.formatDate(data.rejected_datetime) : this.formatDate(val)),
        },
        { key: 'queue', label: 'สถานะ' },
        { key: 'user', label: 'ลูกค้า' },
        { key: 'bank_acc_no', label: 'โอนไปยังบช' },
        { key: 'detail', label: 'รายการ' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'slip_url', label: 'สลิปโอนเงิน' },
        { key: 'txn_detail', label: 'ข้อมูลลูกค้า' },
        { key: 'reason', label: 'หมายเหตุ' },
      ],
      unsuccessTableFields: [
        { key: 'act_actions', label: 'จัดการ' },
        { key: 'act_bot', label: 'บอท' },
        { key: 'status', label: 'สถานะ' },
        { key: 'request_datetime', label: 'แจ้งถอน', formatter: val => this.formatDate(val) },
        { key: 'user', label: 'ลูกค้า' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val) },
        { key: 'bank_acc_no', label: 'บช ลูกค้า' },
        { key: 'detail', label: 'รายการ' },
        { key: 'txn_detail', label: 'ข้อมูลลูกค้า' },
        // { key: 'txn_withdraws', label: 'การถอน' },
        // { key: 'txn_bet', label: 'การเล่น' },
      ],
      depositsTableFields: [
        { key: 'created_at', label: 'วันที่', formatter: val => this.formatDate(val) },
        { key: 'note', label: 'รายละเอียด' },
        { key: 'amount', label: 'จำนวนเงิน', formatter: val => this.numberWithCommas(val || 0) },
        { key: 'sum', label: 'รวมยอด', formatter: val => this.numberWithCommas(val || 0) },
      ],
      fields: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'bank_acc_no', label: 'เลขที่บัญชี' },
        { key: 'credit', label: 'ยอดเครดิต' },
        { key: 'get_promo', label: 'รับโปร' },
        { key: 'invited_by', label: 'คนชวน' },
        { key: 'created_at', label: 'สมัครเมื่อ' },
        { key: 'updated_at', label: 'เข้าเล่นล่าสุด' },
        { key: 'status', label: 'สถานะ' },
        { key: 'afflink', label: 'ลิ้งชวนเพื่อน' },
        { key: 'btn_credit', label: 'อัพเดดเครดิต' },
        { key: 'btn_detail', label: 'แก้ไขข้อมูล' },
      ],
      stats: [
        {
          color: 'light-danger',
          customClass: 'mb-2 mb-xl-0',
          icon: 'TrendingUpIcon',
          subtitle: 'จำนวนรายการออก',
          title: '',
        },
        {
          color: 'light-danger',
          customClass: '',
          icon: 'DollarSignIcon',
          subtitle: 'จำนวนเงินออก',
          title: '',
        },
      ],
      /* eslint-disable global-require */
      successItems: [
      ],
      unsuccessItems: [
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    const ctx = this
    console.log('on_withdraw_stats::init', this.getAPPCode())
    firebase.database().ref(this.getAPPCode()).child('richs_stats/withdraw_today').on('value', snapshot => {
      const val = snapshot.val()
      console.log('on_withdraw_stats', val)
      ctx.stats[0].title = `${(val.count_successful || 0)}`
      ctx.stats[1].title = `${this.numberWithCommas(val.sum_successful || 0)}`
    })
    firebase.database().ref(this.getAPPCode()).child('richs_stats/waiting_withdraw').on('value', snapshot => {
      // ctx.fetchIncompleteList()
    })

    this.refRecheck = setInterval(() => {
      if (ctx.refRecheck == null)
        return
      ctx.fetchIncompleteList()
      // ctx.fetchCompletedList()
      if (ctx.$refs.successWithdrawTable)
        ctx.$refs.successWithdrawTable.refresh()
    }, 45000)
    this.totalRows = this.successItems.length
    this.totalIncompletedRows = this.unsuccessItems.length
    this.fetchBankAccounts()
    this.fetchIncompleteList()
    // this.fetchCompletedList()
    if (ctx.$refs.successWithdrawTable)
      ctx.$refs.successWithdrawTable.refresh()
  },
  methods: {
    getHtmlImageSlip(url) {
      return `<img src="${url}">`
    },
    async fetchBankAccounts() {
      const { data: { bankaccounts } } = await this.$http.get('bankaccounts')
      localStorage.setItem('bankaccount-list', JSON.stringify(bankaccounts.data))
      this.withdrawableBanks = (bankaccounts.data || []).filter(b => b.acc_type === 'OUT' && b.active === 'Y')
      console.log('fetchBankAccounts', bankaccounts)
    },
    searchMember() {
      this.$refs.successWithdrawTable.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
        startdate: moment().format('YYYY-MM-DD 00:00:00'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
      }
      this.$refs.successWithdrawTable.refresh()
    },
    async changePage(page, mtype) {
      if (mtype === 'deposit') {
        this.modalDeposit.page = page
      } if (mtype === 'withdraw') {
        this.modalWithdraw.page = page
      }
      this.$forceUpdate()
    },
    async viewBetList(bankTransferData) {
      console.log('viewBetList', bankTransferData)
      this.modalBetTransactions.show = true
      this.modalBetTransactions.username = bankTransferData.user.username
      this.modalBetTransactions.title = `รายการเล่นของ ${bankTransferData.user.username} ${bankTransferData.user.fname} ${bankTransferData.user.lname}`
    },
    async fetchCompletedList() {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key])
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
        query += '&status=completed'
      }

      const { data: _withdraws } = await this.$http.get(`bot-withdraws${query !== '' ? (`?${query}`) : ''}`)
      this.successItems = _withdraws.withdraws.data
      this.totalRows = _withdraws.withdraws.meta.total
      return this.successItems
    },
    async fetchIncompleteList() {
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key])
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
        query += '&status=incomplete'
      }

      const { data: _withdraws } = await this.$http.get(`bot-withdraws${query !== '' ? (`?${query}`) : ''}`)
      this.unsuccessItems = _withdraws.withdraws.data
      this.totalIncompletedRows = _withdraws.withdraws.meta.total
      return this.unsuccessItems
    },
    async fetchUserDepositList() {
      let query = ''
      this.modalDeposit.platform = 'WALLET'
      this.modalDeposit.busy = true
      if (this.modalDeposit !== {}) {
        Object.keys(this.modalDeposit).forEach((key, idx) => {
          if (key === 'data')
            return
          if (idx > 0) query += '&'
          if (this.modalDeposit[key].value)
            query += `${key}=${encodeURIComponent(this.modalDeposit[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.modalDeposit[key])}`
        })
      }

      if (this.modalDeposit.username === null || this.modalDeposit.username === undefined) {
        console.log('fetchUserDepositList::no username provided')
        this.modalDeposit.data = []
        this.modalDeposit.busy = false
        return
      }

      try {
        const { data: _wallettx } = await this.$http.get(`wallet-transactions${query !== '' ? (`?${query}`) : ''}&mode=user-deposits`)
        this.modalDeposit.data = _wallettx.wallet_transactions.data
        this.modalDeposit.totalRows = _wallettx.wallet_transactions.meta.total
        this.modalDeposit.busy = false
        let sumDeposit = 0
        this.modalDeposit.data.forEach(depositItem => {
          sumDeposit += parseFloat(depositItem.amount)
          depositItem.sum = sumDeposit
        })
      } catch (err) {
        this.modalDeposit.busy = false
      }
      this.$forceUpdate()
    },
    async fetchUserWithdrawList() {
      let query = ''
      this.modalWithdraw.platform = 'WALLET'
      this.modalWithdraw.busy = true
      if (this.modalWithdraw !== {}) {
        Object.keys(this.modalWithdraw).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.modalWithdraw[key].value)
            query += `${key}=${encodeURIComponent(this.modalWithdraw[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.modalWithdraw[key])}`
        })
      }

      if (this.modalWithdraw.username === null || this.modalWithdraw.username === undefined) {
        this.modalWithdraw.data = []
        return
      }

      try {
        const { data: _wallettx } = await this.$http.get(`wallet-transactions${query !== '' ? (`?${query}`) : ''}&mode=user-withdraws`)
        this.modalWithdraw.data = _wallettx.wallet_transactions.data
        this.modalWithdraw.totalRows = _wallettx.wallet_transactions.meta.total
        this.modalWithdraw.busy = false
        let sumWithdraw = 0
        this.modalWithdraw.data.forEach(withdrawItem => {
          sumWithdraw += parseFloat(withdrawItem.amount)
          // eslint-disable-next-line no-param-reassign
          withdrawItem.sum = sumWithdraw
        })
      } catch (err) {
        this.modalWithdraw.busy = false
      }
      this.$forceUpdate()
    },
    viewSlip(data) {
      this.modalSlip.show = true
      this.modalSlip.slip_url = data.slip_url
    },
    async viewDeposit(withdrawData) {
      this.modalDeposit.show = true
      this.modalDeposit.username = withdrawData.user.username
      this.modalDeposit.page = 1
      this.modalDeposit.page_size = 20
      this.modalDeposit.data = []
      this.modalDeposit.totalRows = 0
      this.modalDeposit.title = `รายการฝากของ ${withdrawData.user.username} ${withdrawData.user.fname} ${withdrawData.user.lname}`
      this.fetchUserDepositList()
    },
    viewWithdraw(withdrawData) {
      this.modalWithdraw.show = true
      this.modalWithdraw.username = withdrawData.user.username
      this.modalWithdraw.page = 1
      this.modalWithdraw.page_size = 20
      this.modalWithdraw.data = []
      this.modalWithdraw.totalRows = 0
      this.modalWithdraw.title = `รายการถอนของ ${withdrawData.user.username} ${withdrawData.user.fname} ${withdrawData.user.lname}`
      this.fetchUserWithdrawList()
    },
    viewWalletList(withdrawData) {
      this.modalWalletTxn.show = true
      this.modalWalletTxn.username = withdrawData.user.username
      this.modalWalletTxn.page = 1
      this.modalWalletTxn.page_size = 20
      this.modalWalletTxn.data = []
      this.modalWalletTxn.totalRows = 0
      // this.modalWithdraw.title = `รายการถอนของ ${withdrawData.user.username} ${withdrawData.user.fname} ${withdrawData.user.lname}`
    },
    async manualWithdrawRecord(record, changes) {
      const { data: manualResult } = await this.$http.put(`withdraws/manual/${record.id}`, changes)
      if (manualResult.success) {
        const withdrawItem = this.unsuccessItems[this.unsuccessItems.indexOf(record)]
        withdrawItem.status = 'manual'
        withdrawItem.status_text = 'ถอนมือ'
        this.$bvToast.toast(manualResult.note, {
          variant: 'success',
          title: 'ตั้งรายการถอนมือสำเร็จ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      } else {
        this.$bvToast.toast(manualResult.note, {
          variant: 'danger',
          title: 'เกิดข้อผิดพลาดตั้งรายการถอนมือ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      }
    },
    async rejectWithdraw(data) {
      this.$swal({
        title: 'ยืนยันการปฏิเสธการถอนเงินของสมาขิก',
        text: `คุณกำลังจะปฏิเสธการถอนเงินของสมาชิก ${data.user.phoneno} ${data.user.fname} ${data.user.lname} จำนวน ${data.amount} ใช่ไหม?   *** เงินจะถูกคืนกลับไปยังกระเป๋าเงินลูกค้า ***`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.modalRejectWithdraw.show = true
          this.modalRejectWithdraw.data = data
        }
        return { data: { success: false } }
      })
    },
    async confirmRejectWithdraw() {
      const { reason, data } = this.modalRejectWithdraw
      const ctx = this

      if (reason.length < 5) {
        this.$bvToast.toast('กรุณาระบุเหตุผลการปฏิเสธให่ชัดเจน', {
          variant: 'danger',
          title: 'เกิดข้อผิดพลาดปฎิเสธการถอน',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
        return
      }

      try {
        const { data: { success } } = await this.$http.put(`withdraws/reject/${data.id}`, { note: reason, data })
        if (success) {
          const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          withdrawItem.status_text = 'ปฎิเสธ'
          withdrawItem.status = 'rejected'
          ctx.modalRejectWithdraw.show = false
          ctx.modalRejectWithdraw.reason = ''
          delete ctx.modalRejectWithdraw.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    markComplete(data) {
      this.$swal({
        title: 'ยืนยันการถอนมือเรียบร้อย',
        text: `คุณกำลังจะเปลี่ยนสถานะการถอนเงินเรียบร้อย ของสมาชิก ${data.user.phoneno} ${data.user.fname} ${data.user.lname} จำนวน ${data.amount} ใช่ไหม?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.modalMarkCompleteWithdraw.show = true
          this.modalMarkCompleteWithdraw.data = data
        }
        return { data: { success: false } }
      })
    },
    async confirmMarkWithdrawComplete() {
      const { reason, data } = this.modalMarkCompleteWithdraw
      const ctx = this
      try {
        const { data: { success } } = await this.$http.put(`withdraws/manual/${data.id}`, { queue: 'M', status: 'approved', note: reason })
        if (success) {
          const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          withdrawItem.status_text = 'เรียบร้อย'
          withdrawItem.status = 'approved'
        }
      } catch (err) {
        console.log(err)
      }
    },
    cancelMarkWithdraw() {
      this.modalMarkCompleteWithdraw.show = false
      this.modalMarkCompleteWithdraw.data = null
      this.modalMarkCompleteWithdraw.reason = ''
    },
    async seizeWithdraw(data) {
      this.modalSeizeWithdraw.show = true
      this.modalSeizeWithdraw.data = data
    },
    async cancelSeizedWithdraw() {
      this.modalSeizeWithdraw.show = false
      this.modalSeizeWithdraw.data = null
      this.modalSeizeWithdraw.reason = ''
    },
    async confirmSeizedWithdraw() {
      const { reason, data } = this.modalSeizeWithdraw
      const ctx = this
      try {
        const { data: { success } } = await this.$http.put(`withdraws/manual/${data.id}`, { queue: 'M', status: 'seized', note: reason })
        if (success) {
          const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          withdrawItem.status_text = 'ยึดเครดิต'
          withdrawItem.status = 'seized'
        }
      } catch (err) {
        console.log(err)
      }
    },
    async cancelOperation(data) {
      let title = 'ยืนยันการยกเลิกการถอนมือ'
      let text = 'คุณแน่ใจหรือไม่ (การยกเลิกถอนมืออาจทำให้เกิดการถอนซ้ำ คุณจะต้องเป็นผู้รับผิดชอบต่อการกระทำนี้)'
      if (data.status === 'request') {
        title = 'ยืนยันการยกเลิกการถอนด้วยบอทออโต้'
        text = 'คุณแน่ใจหรือไม่ การยกเลิกการถอนด้วยบอทออโต้นั้นไม่ 100% เนื่องจาก ณ เวลาที่คุณกดบอทออโต้อาจเริ่มทำรายการโอนแล้ว กรุณาตรวจสอบรายการให้แน่ใจอีกครั้ง'
      }
      const ctx = this
      this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.put(`withdraws/manual/${data.id}`, { queue: 'M', status: 'awaiting', note: 'ยกเลิก/รออนุมัติใหม่' })
        return { data: { success: false } }
      }).then(({ data: result }) => {
        if (result.success) {
          const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          withdrawItem.status_text = 'รออนุมัติการถอน'
          withdrawItem.status = 'awaiting'
          withdrawItem.queue = 'M'
          console.log('result', result)
        } else {
          console.log('result', result)
        }
      })
    },
    async approveWithdraw(data, queue) {
      const ctx = this
      this.$swal({
        title: 'อนุมัติการถอน',
        text: `ยืนยันการอนุมัติถอนเงินให้แก่ ${data.user.phoneno} ${data.user.fname} ${data.user.lname} จำนวน ${data.amount} หรือไม่?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        cancelButtonText: 'ไม่ใช่',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.put(`withdraws/approve/${data.id}`, { queue, data, user: data.user })
        return { data: { success: false } }
      }).then(({ data: result }) => {
        if (result.success) {
          const withdrawItem = ctx.unsuccessItems[ctx.unsuccessItems.indexOf(data)]
          withdrawItem.status_text = 'รอดำเนินการ'
          withdrawItem.status = 'request'
          withdrawItem.queue = queue
          console.log('result', result)
        } else {
          console.log('result', result)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.tooltip { top: 0; }
.b-tooltip:not([style*='transform']) { top: 0; }
</style>
